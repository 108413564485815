import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// import awsConfig from './../aws-exports'

// third-party
// import { CognitoUserPool } from 'amazon-cognito-identity-js';

// action - state management
import { LOGIN, LOGOUT } from './../store/reducers/actions';
import authReducer from './../store/reducers/auth';

// project imports
import Loader from './../components/Loader';
import { dispatch } from '../store';
import { Auth } from 'aws-amplify';
// import { AWS_API } from 'config';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// export const userPool = new CognitoUserPool({
//   UserPoolId: awsConfig.aws_user_pools_id,
//   ClientId: awsConfig.aws_user_pools_web_client_id
// });

const setSession = (serviceToken: string | null) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
  } else {
    localStorage.removeItem('serviceToken');
  }
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //

const login = async (email: any, password: any) => {
  const loginInfo = await Auth.signIn({
    username: email,
    password: password,
  })
  console.log("jwt token: ",loginInfo.signInUserSession.accessToken.jwtToken);



  // usr.authenticateUser(authData, {
  //   onSuccess: (session) => {
  //     console.log(session.getAccessToken().getJwtToken());

  //     debugger;
  setSession(loginInfo.signInUserSession.accessToken.jwtToken);

  dispatch({
    type: LOGIN,
    payload: {
      isLoggedIn: true,
      user: {
        email: loginInfo.username,
        name: `${loginInfo.attributes.given_name} ${loginInfo.attributes.family_name}`,

      }
    }
  });
  return loginInfo;
  
}
// onFailure: () => {},
// newPasswordRequired: () => {
//   // // User was signed up by an admin and must provide new
//   // // password and required attributes, if any, to complete
//   // // authentication.
//   // // the api doesn't accept this field back
//   // delete userAttributes.email_verified;
//   // // unsure about this field, but I don't send this back
//   // delete userAttributes.phone_number_verified;
//   // // Get these details and call
//   // usr.completeNewPasswordChallenge(password, userAttributes, requiredAttributes);
// }
// });
// };

export const AWSCognitoContext = createContext({initialState,login});


export const AWSCognitoProvider = (props: any) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    debugger;
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken) {
          setSession(serviceToken);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                name: 'Betty'
              }
            }
          });
        } else {
          dispatch({
            type: LOGOUT,
            payload: {
              isLoggedIn: false,
              user: undefined
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
          payload: {
            isLoggedIn: false,
            user: undefined
          }
        });
      }
    };

    init();
  }, []);



  // const register = (email: string, password: string, firstName: any, lastName: any) =>
  //   new Promise((success, rej) => {
  //     userPool.signUp(
  //       email,
  //       password,
  //       [
  //         new CognitoUserAttribute({ Name: 'email', Value: email }),
  //         new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
  //       ],
  //       [],
  //       async (err, result) => {
  //         if (err) {
  //           rej(err);
  //           return;
  //         }
  //         success(result);
  //       }
  //     );
  //   });

  // const logout = () => {
  //   const loggedInUser = userPool.getCurrentUser();
  //   if (loggedInUser) {
  //     setSession(null);
  //     loggedInUser.signOut();
  //     dispatch({
  // 	  type: LOGOUT,
  // 	  payload: {
  // 		  isLoggedIn: false,
  // 		  user: undefined
  // 	  }
  //   });
  //   }
  // };

  // const resetPassword = async () => {};
  // const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    // eslint-disable-next-line no-sequences
    <AWSCognitoContext.Provider value={{initialState,login}}>
      {props.children}
    </AWSCognitoContext.Provider>
  );
};

AWSCognitoProvider.propTypes = {
  children: PropTypes.node
};

export default AWSCognitoContext;
